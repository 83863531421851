<script setup lang="ts"></script>

<template>
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_273_932)">
            <path
                d="M23.7471 16.4434L21.4732 14.1692L16.769 18.8734C15.9293 19.7131 14.8753 20.2606 13.7305 20.4655L16.7278 23.4628C17.0023 23.7374 17.4476 23.7374 17.7221 23.4628L23.7471 17.4379C24.0218 17.1632 24.0218 16.7179 23.7471 16.4434Z"
                fill="currentColor"
            />
            <path
                d="M20.5468 13.1069C21.3395 12.3144 21.3397 11.0248 20.5479 10.2317C20.547 10.2308 20.5461 10.2299 20.5452 10.229C20.3493 10.0332 20.1204 9.88236 19.8712 9.78147C20.0872 9.2349 20.1328 8.31058 19.4171 7.59464C19.4152 7.59281 19.4134 7.5908 19.4114 7.58896C19.2164 7.39524 18.989 7.24582 18.7414 7.14548C18.9575 6.59928 19.0032 5.67478 18.2879 4.9592C18.2873 4.95865 18.2869 4.95829 18.2866 4.95792C18.0905 4.76181 17.8614 4.61057 17.6118 4.50968C17.8279 3.96311 17.8735 3.03861 17.1577 2.32266C17.1575 2.32266 17.1579 2.32266 17.1577 2.32266C17.1575 2.32248 17.157 2.32212 17.1568 2.32193C16.3638 1.5289 15.0736 1.5289 14.2806 2.32193L12.9474 3.65494L13.6291 4.33664C14.7651 5.47263 14.7651 7.3209 13.6293 8.45689C13.247 8.83903 12.7773 9.1016 12.2653 9.22666C12.1428 9.72581 11.8872 10.199 11.4978 10.5882C11.116 10.97 10.647 11.2324 10.1356 11.3576C10.0106 11.8689 9.74799 12.338 9.3664 12.7196C8.98462 13.1014 8.51569 13.3637 8.00428 13.489C7.87922 14.0002 7.61664 14.4693 7.23505 14.8511C6.68482 15.4012 5.95313 15.7044 5.17493 15.7044C4.39673 15.7044 3.66504 15.4013 3.11481 14.8509L2.43311 14.1692L0.159119 16.4434C-0.11554 16.7181 -0.11554 17.1632 0.159119 17.4378L6.18403 23.4628C6.45868 23.7374 6.90381 23.7372 7.17847 23.4628L11.4913 19.1501H12.7064C13.8655 19.1501 14.9551 18.6985 15.7747 17.8791L20.5468 13.1069Z"
                fill="currentColor"
            />
            <path
                d="M4.10926 13.8567C4.69776 14.4454 5.6521 14.4454 6.2406 13.8567C6.82929 13.2682 6.82929 12.314 6.2406 11.7253C6.82929 12.314 7.78345 12.314 8.37214 11.7253C8.96064 11.1368 8.96064 10.1825 8.37214 9.59397C8.96064 10.1825 9.91498 10.1825 10.5035 9.59397C11.092 9.00547 11.092 8.05112 10.5035 7.46262C11.092 8.05112 12.0463 8.05112 12.6348 7.46262C13.2235 6.87394 13.2235 5.91977 12.6348 5.33109L9.62971 2.32596C9.04121 1.73746 8.08686 1.73746 7.49836 2.32596C6.90986 2.91446 6.90986 3.86881 7.49836 4.45731L7.87482 4.83396C7.28632 4.24527 6.33197 4.24527 5.74347 4.83396C5.15497 5.42246 5.15497 6.37681 5.74347 6.96531L6.49659 7.71842C5.90808 7.12992 4.95374 7.12992 4.36524 7.71842C3.77655 8.30692 3.77655 9.26127 4.36524 9.84977L5.11835 10.6031C4.52967 10.0144 3.5755 10.0144 2.98682 10.6031C2.39832 11.1916 2.39832 12.1457 2.98682 12.7344L4.10926 13.8567Z"
                fill="currentColor"
            />
            <path
                d="M13.2861 1.32749C13.3901 1.22348 13.5002 1.1279 13.6146 1.03909L13.2034 0.627839C12.9895 0.413789 12.6623 0.360871 12.3919 0.496003L10.6564 1.36374L11.9531 2.66068L13.2861 1.32749Z"
                fill="currentColor"
            />
        </g>
        <defs>
            <clipPath id="clip0_273_932">
                <rect width="24" height="24" fill="currentColor" />
            </clipPath>
        </defs>
    </svg>
</template>
